import { CommonModule, NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PlanType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { TranslateModule } from '@ngx-translate/core';

import { SidebarMenuItem } from '../sidebar.model';

@Component({
  selector: 'sidebar-menu',
  templateUrl: './sidebar-item.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, NgIf, NgForOf, IconComponent, TranslateModule],
})
export class SidebarItemComponent {
  @Input()
  public title: string;
  @Input()
  public menuItems: SidebarMenuItem[];
  @Input()
  public menuItem: SidebarMenuItem;
  @Output()
  public onSelect = new EventEmitter();
  @Output()
  public onLocked = new EventEmitter();
  @Output()
  public closeSideBar = new EventEmitter<boolean>();
  public planType = PlanType;

  public expanded(menuItem: SidebarMenuItem): void {
    if (menuItem.state.isLocked) {
      return;
    }

    this.menuItems.map((m) => menuItem.label !== m.label && (m.expanded = false));
    if (menuItem.items) {
      menuItem.expanded = !menuItem.expanded;
    }
  }

  public activeChanged(active: boolean, menuItem: SidebarMenuItem, item: SidebarMenuItem): void {
    if (item?.state?.isLocked) {
      return;
    }

    if (active && !menuItem.expanded) {
      this.expanded(menuItem);
    }

    const getUrls: string[] = item?.items?.map((item) => item.routerLink);
    const checkUrlExist: boolean = getUrls?.includes(item.routerLink);
    if (active && !checkUrlExist) {
      this.onSelect.emit(item);
    }
  }

  public handleLock(item: SidebarMenuItem): void {
    if (item?.state?.isLocked && item?.state?.lockType) {
      this.onLocked.emit(item?.state?.lockType);
    }
  }
}
