<div
  class="scrollbar-hide relative z-[1] flex h-[calc(100vh-49px)] min-h-[calc(100vh-49px)] min-w-[256px] max-w-[256px] flex-col overflow-y-auto border-r border-grey-200 bg-white py-[29px] pl-[20px] pr-[14px] shadow-[0_2px_15px_0_rgba(199,199,199,0.50)] transition-width duration-150"
>
  <div class="mb-[10px] ml-[10px] font-bold leading-4 text-word-mark-800">{{ title }}</div>
  <div class="parent-menu flex flex-col">
    <ng-container *ngFor="let menuItem of menuItems; trackBy: trackByRouterLink">
      <sidebar-menu
        class="relative mb-[14px] rounded-md last:mb-0"
        [attr.aria-label]="menuItem.label"
        [menuItem]="menuItem"
        [menuItems]="menuItems"
        (onSelect)="setSelectedMenu($event)"
        (onLocked)="setLockedMenu($event)"
      ></sidebar-menu>
    </ng-container>
  </div>

  <div *ngIf="showChargebeeTestButtons" class="mt-auto flex flex-col">
    <button (click)="importChargeBee()" class="btn btn-primary mb-2" type="button">
      {{ 'Import from ChargeBee' | translate }}
    </button>
    <button (click)="pushChargebee()" class="btn btn-primary" type="button">
      {{ 'Push to ChargeBee' | translate }}
    </button>
  </div>
</div>
