<div class="card" table-group-content>
  <div class="card-heading">
    <div class="card-heading__title">
      {{ 'Locations' | translate }}
    </div>

    <div class="actions">
      <button
        class="btn btn-white mr-2 flex items-center"
        *ngIf="showArchivedDepartments"
        (click)="toggleShowArchivedDepartments()"
      >
        <icon svgIcon="eye-slash"></icon>&nbsp;
        {{ 'Hide inactive items' | translate }}
      </button>
      <button
        class="btn btn-white mr-2 flex items-center"
        *ngIf="!showArchivedDepartments"
        (click)="toggleShowArchivedDepartments()"
      >
        <icon svgIcon="eye"></icon>&nbsp;
        {{ 'Show inactive items' | translate }}
      </button>
      &nbsp;
      <ng-container *ngIf="menuItems.length > 1; else singleButton">
        <button
          [sbDropdownToggle]="menu"
          sb-button
          [color]="'primary'"
          [sbDropdownPlacement]="'bottom-right'"
          [disabled]="hasReachedTeamQuota && isFreePlan"
        >
          <sb-icon [name]="'plus'"></sb-icon>{{ 'Add' | translate }}
        </button>
        <ng-template #menu>
          <div sbDropdownMenu>
            <button *ngFor="let menu of menuItems" sbDropdownItem (sbDropdownItemTriggered)="menu.command()">
              {{ menu.label | translate }}
            </button>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #singleButton>
        <ng-container *ngIf="menuItems.length > 0">
          <button
            sb-button
            [color]="'primary'"
            [disabled]="hasReachedTeamQuota && isFreePlan"
            (click)="menuItems[0].command()"
          >
            <sb-icon [name]="'plus'"></sb-icon>{{ menuItems[0].label | translate }}
          </button>
        </ng-container>
      </ng-template>
    </div>
  </div>

  <div class="card-table">
    <table class="table-border table-locations-updated">
      <tbody>
        <ng-container *ngFor="let location of locations">
          <tr class="table-locations__location-group" [ngClass]="{ 'inactive-location': location.deleted }">
            <td>
              <span>{{ location.name }}</span>
            </td>
            <td class="pr-2 text-right">
              <span
                class="inline-block rounded bg-grey-200 px-[0.5em] py-[0.3em] text-[12px] font-bold leading-none text-grey-600"
              >
                {{ 'Location' | translate }}</span
              >
            </td>
            <td class="table-border-padding__actions">
              <button
                sb-icon-button
                [color]="'transparent'"
                [attr.aria-label]="'Edit' | translate"
                [sbTooltip]="'Edit' | translate"
                [routerLink]="['', { outlets: { modal: ['location-group', location.id] } }]"
              >
                <sb-icon [name]="'pen'"></sb-icon>
              </button>
              <button
                sb-icon-button
                [color]="'transparent'"
                [attr.aria-label]="'Delete' | translate"
                [sbTooltip]="'Delete' | translate"
                *ngIf="!location.deleted && !location.loading"
                (click)="deactivateLocationGroup(location.id)"
              >
                <sb-icon [name]="'trash'"></sb-icon>
              </button>
              <ng-container *subscriptionQuota="quotaType.DEPARTMENTS">
                <button
                  sb-icon-button
                  [color]="'transparent'"
                  [attr.aria-label]="'Activate' | translate"
                  [sbTooltip]="'Activate' | translate"
                  *ngIf="location.deleted && !location.loading"
                  (click)="activateLocationGroup(location.id)"
                >
                  <sb-icon [name]="'check'"></sb-icon>
                </button>
              </ng-container>
              <div class="inline-block">
                <sb-spinner *ngIf="location.loading && !location.deleted" class="small"></sb-spinner>
                <sb-spinner *ngIf="location.loading && location.deleted" class="small" [isWhite]="true"></sb-spinner>
              </div>
            </td>
          </tr>

          <ng-container *ngFor="let department of departments[location.id]">
            <tr [ngClass]="{ inactive: department.deleted }">
              <td class="table-locations__location flex items-center">
                <icon class="si mr-3 h-[18px] w-[18px] pb-1.5 text-grey-400" [svgIcon]="'child-row-indicator'"></icon>
                <span class="table-locations__location--name">{{ department.name }}</span>
              </td>
              <td class="pr-2 text-right">
                <span
                  class="inline-block rounded bg-grey-200 px-[0.5em] py-[0.3em] text-[12px] font-bold leading-none text-grey-600"
                >
                  {{ 'Department' | translate }}</span
                >
              </td>
              <td class="table-border-padding__actions">
                <button
                  sb-icon-button
                  [color]="'transparent'"
                  [attr.aria-label]="'Edit' | translate"
                  [sbTooltip]="'Edit' | translate"
                  [routerLink]="['', { outlets: { modal: ['location', department.id] } }]"
                >
                  <sb-icon [name]="'pen'"></sb-icon>
                </button>
                <ng-container *permission="'Manage account'; user: 'me'; department: department.id">
                  <span *ngIf="!department.deleted && !department.loading" class="relative">
                    <button
                      sb-icon-button
                      [color]="'transparent'"
                      [attr.aria-label]="'Delete' | translate"
                      [sbTooltip]="departmentTooltip"
                      (click)="deactivateDepartment(department.id)"
                      [disabled]="departmentsCount <= 1"
                    >
                      <sb-icon [name]="'trash'"></sb-icon>
                    </button>
                  </span>
                </ng-container>
                <ng-container *subscriptionQuota="quotaType.DEPARTMENTS">
                  <button
                    sb-icon-button
                    [color]="'transparent'"
                    [attr.aria-label]="'Activate' | translate"
                    [sbTooltip]="'Activate' | translate"
                    *ngIf="department.deleted && !department.loading && !location.loading"
                    (click)="activateDepartment(department.id)"
                  >
                    <sb-icon [name]="'check'"></sb-icon>
                  </button>
                </ng-container>
                <div class="inline-block">
                  <sb-spinner
                    *ngIf="(department.loading || location.loading) && (department.deleted || location.deleted)"
                    class="small"
                    [isWhite]="true"
                  ></sb-spinner>

                  <sb-spinner
                    *ngIf="(department.loading || location.loading) && !department.deleted && !location.deleted"
                    class="small"
                  ></sb-spinner>
                </div>
              </td>
            </tr>

            <tr
              *ngFor="let team of allTeams[department.id]"
              [ngClass]="{ inactive: team.deleted || department.deleted }"
            >
              <td class="table-locations__location__team flex items-center">
                <icon class="si mr-3 h-[18px] w-[18px] pb-1.5 text-grey-400" [svgIcon]="'child-row-indicator'"></icon>

                <ng-container [ngSwitch]="team.type">
                  <span
                    *ngSwitchCase="TeamType.DEFAULT"
                    class="white-pointer relative mr-3 h-[18px] w-[18px] rounded-[5px]"
                    [ngClass]="{ 'white-pointer': team.color_is_dark }"
                    [style.backgroundColor]="team.color"
                  ></span>

                  <span
                    *ngSwitchCase="TeamType.FLEXPOOL"
                    class="white-pointer relative mr-3 flex h-[18px] w-[18px] items-center justify-center rounded-[5px]"
                    [ngClass]="{ 'white-pointer': team.color_is_dark }"
                    [style.backgroundColor]="team.color"
                  >
                    <sb-icon class="h-3 w-3 text-white" [name]="'refresh'"></sb-icon>
                  </span>

                  <span
                    *ngSwitchCase="TeamType.HIDDEN"
                    class="white-pointer relative mr-3 flex h-[18px] w-[18px] items-center justify-center rounded-[5px]"
                    [ngClass]="{ 'white-pointer': team.color_is_dark }"
                    [style.backgroundColor]="team.color"
                  >
                    <sb-icon class="h-3 w-3 text-white" [name]="'eye-slash'"></sb-icon>
                  </span>
                </ng-container>

                <span class="table-locations__location--name">{{ team.name }}</span>
              </td>
              <td class="pr-2 text-right">
                <span
                  class="inline-block rounded bg-grey-200 px-[0.5em] py-[0.3em] text-[12px] font-bold leading-none text-grey-600"
                >
                  {{ 'Team' | translate }}</span
                >
              </td>
              <td class="table-border-padding__actions">
                <button
                  *permission="'Edit teams'; user: 'me'; department: team.department_id"
                  sb-icon-button
                  [color]="'transparent'"
                  [attr.aria-label]="'Edit' | translate"
                  [sbTooltip]="'Edit' | translate"
                  [routerLink]="['', { outlets: { modal: ['team', 'edit', team.id] } }]"
                >
                  <sb-icon [name]="'pen'"></sb-icon>
                </button>

                <ng-container *permission="'Delete teams'; user: 'me'; department: team.department_id">
                  <span
                    *ngIf="!team.deleted && !team.loading && !department.loading"
                    class="relative"
                    [sbTooltip]="allTeams[department.id].length > 1 ? undefined : teamTooltip"
                  >
                    <button
                      sb-icon-button
                      [color]="'transparent'"
                      [attr.aria-label]="'Delete team' | translate"
                      [sbTooltip]="'Delete team' | translate"
                      [appDeleteTeam]="{ teamId: team.id, departmentId: department.id }"
                      [disabled]="allTeams[department.id].length <= 1"
                    >
                      <sb-icon [name]="'trash'"></sb-icon>
                    </button>
                  </span>
                </ng-container>
                <div class="inline-block">
                  <sb-spinner
                    *ngIf="(team.loading || department.loading) && (team.deleted || department.deleted)"
                    class="small"
                    [isWhite]="true"
                  ></sb-spinner>

                  <sb-spinner
                    *ngIf="(team.loading || department.loading) && !team.deleted && !department.deleted"
                    class="small"
                  ></sb-spinner>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<ng-template #teamTooltip>
  <div class="w-max text-left">
    <div>
      {{ 'Every department must' | translate }}
    </div>
    <div>
      {{ 'have, at minimum, one team.' | translate }}
    </div>
  </div>
</ng-template>

<ng-template #departmentTooltip>
  @if (departmentsCount <= 1) {
    <div class="w-max text-left">
      <div class="inline-block">
        {{ 'You need to have at least' | translate }}
      </div>
      <div>
        {{ ' one active department' | translate }}
      </div>
    </div>
  } @else {
    {{ 'Delete' | translate }}
  }
</ng-template>
