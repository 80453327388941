<div
  [routerLink]="menuItem.routerLink && !menuItem?.state?.isLocked ? [menuItem.routerLink] : null"
  [routerLinkActive]="!menuItem?.state?.isLocked ? 'active' : ''"
  [routerLinkActiveOptions]="!menuItem?.state?.isLocked ? menuItem?.routerLinkActiveOptions : {}"
  #settingsItem="routerLinkActive"
  [ngClass]="{ expanded: menuItem?.expanded }"
  (isActiveChange)="activeChanged(settingsItem.isActive, menuItem, menuItem)"
  (click)="handleLock(menuItem)"
  class="text-base cursor-pointer"
>
  <div
    class="group py-[5px] px-[11px] rounded-md flex flex-row items-center content-center font-semibold"
    [ngClass]="{
      'hover:bg-grey-100': !(settingsItem.isActive && !menuItem?.items) && !menuItem?.expanded,
      'bg-primary-50 !important': settingsItem.isActive && !menuItem?.items,
      'bg-grey-100': menuItem?.state?.isLocked
    }"
    (click)="expanded(menuItem)"
  >
    <icon
      *ngIf="menuItem.icon"
      class="si mr-[20px] w-[17px] h-[17px]"
      [ngClass]="{
        'text-primary-500 !important': (settingsItem.isActive && !menuItem?.items) || menuItem?.expanded,
        'text-grey-400 group-hover:text-grey-500': menuItem?.state?.isLocked,
        'text-grey-400': !settingsItem.isActive
      }"
      [svgIcon]="!menuItem?.state?.isLocked ? menuItem.icon : 'lock'"
    ></icon>

    <span
      [ngClass]="{
        'text-primary-500': settingsItem.isActive && !menuItem?.items,
        'text-grey-400 group-hover:text-grey-500': menuItem?.state?.isLocked
      }"
    >
      {{ menuItem.label | translate }}
    </span>

    <icon
      *ngIf="menuItem?.items?.length > 0"
      class="ml-auto text-grey-400"
      [svgIcon]="menuItem.expanded ? 'minimize' : 'maximize'"
    ></icon>
  </div>

  <div
    *ngIf="menuItem.items?.length > 0"
    class="children-menu relative flex ml-[17px] overflow-hidden ease-in-out duration-300"
    (click)="$event?.stopPropagation()"
    [ngStyle]="{ 'max-height': menuItem?.expanded ? menuItem.items?.length * 64 + 'px' : '0px' }"
  >
    <div class="absolute h-full w-0.5 rounded-3xl bg-grey-300"></div>
    <div class="flex flex-col w-full">
      <ng-container [ngTemplateOutletContext]="{ menuItem: menuItem }" [ngTemplateOutlet]="childMenu"></ng-container>
    </div>
  </div>
</div>

<ng-template #childMenu let-menuItem="menuItem">
  <ng-container *ngFor="let item of menuItem.items">
    <div
      class="group relative rounded-md ml-[14px] mb-[2px] last:mb-0"
      [routerLink]="item.routerLink && !item?.state?.isLocked ? [item.routerLink] : null"
      [routerLinkActive]="!item?.state?.isLocked ? 'active' : ''"
      [routerLinkActiveOptions]="!item?.state?.isLocked ? item?.routerLinkActiveOptions : {}"
      #settingsChildItem="routerLinkActive"
      (isActiveChange)="activeChanged(settingsChildItem.isActive, menuItem, item)"
      (click)="handleLock(item)"
      [ngClass]="{ expanded: item?.expanded }"
    >
      <div
        class="py-[5px] px-[17px] rounded-md flex flex-row items-center content-center font-semibold"
        [ngClass]="{
          'hover:bg-grey-100': !settingsChildItem.isActive,
          '!bg-primary-50': settingsChildItem.isActive,
          'bg-grey-100': item?.state?.isLocked
        }"
      >
        <span
          [ngClass]="{
            'text-primary-500': settingsChildItem.isActive,
            'text-grey-400 group-hover:text-grey-500': item?.state?.isLocked,
            'text-grey-400': !settingsChildItem.isActive
          }"
        >
          {{ item.label | translate }}
        </span>

        <div *ngIf="item.icon || item?.state?.isLocked" class="absolute flex p-1.5 right-1">
          <icon
            class="si w-[17px] h-[17px] text-grey-400"
            [ngClass]="{
              'text-primary-500 !important': settingsChildItem.isActive,
              'text-grey-400 group-hover:text-grey-500': item?.state?.isLocked
            }"
            [svgIcon]="!item?.state?.isLocked ? item.icon : 'lock'"
          ></icon>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
