import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SidebarItemComponent } from './components/sidebar-item.component';
import { SidebarMenuItem } from './sidebar.model';

@Component({
  selector: 'sb-sidebar',
  templateUrl: './sidebar.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SidebarItemComponent, TranslateModule, NgIf, NgForOf],
})
export class SidebarComponent {
  @Input()
  public title: string;
  @Input()
  public menuItems: SidebarMenuItem[];
  @Input()
  public isLoading: boolean;
  @Output()
  public onImport = new EventEmitter();
  @Output()
  public onPush = new EventEmitter();
  @Output()
  public onSet = new EventEmitter();
  @Output()
  public onLocked = new EventEmitter();
  @Input()
  public showChargebeeTestButtons: boolean;

  public setSelectedMenu(event) {
    this.onSet.emit(event);
  }

  public setLockedMenu(event) {
    this.onLocked.emit(event);
  }

  public importChargeBee() {
    this.onImport.emit();
  }

  public pushChargebee() {
    this.onPush.emit();
  }

  public trackByRouterLink(_: string, item: SidebarMenuItem): string {
    return item.routerLink;
  }
}
